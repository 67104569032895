import React, { useState, useEffect, useCallback, useRef } from "react";
import "./satmathQuiz.css";
import MathJaxRenderer from "./MathJaxRenderer";
import confetti from 'canvas-confetti';
import Calculator from "./components/Calculator";
import { Book, Calculator as CalculatorIcon } from "lucide-react";
import { ChartComponent } from "./ChartComponent";
import { generatePDF } from "./components/generatePDF";
import { db } from "./firebase";
import {
  collection,
  query,
  where,
  getDocs,
  doc,
  setDoc,
  getDoc,
  deleteDoc,
} from "firebase/firestore";
import { getAuth, onAuthStateChanged } from "firebase/auth";

const SatMathQuiz = () => {
  const [randomizedQuestions, setRandomizedQuestions] = useState([]);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [selectedAnswers, setSelectedAnswers] = useState([]);
  const [showExplanation, setShowExplanation] = useState(false);
  const [answerStatus, setAnswerStatus] = useState(null);
  const [correctAnswers, setCorrectAnswers] = useState(0);
  const [showScore, setShowScore] = useState(false);
  const [quizSummary, setQuizSummary] = useState([]);
  const [shuffledOptions, setShuffledOptions] = useState([]);
  const [isAnswerSubmitted, setIsAnswerSubmitted] = useState(false);
  const [showWelcome, setShowWelcome] = useState(true);
  const [hasResumableQuiz, setHasResumableQuiz] = useState(false);
  const [savedQuizState, setSavedQuizState] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [userId, setUserId] = useState(null);
  const [shuffledOptionsForQuestions, setShuffledOptionsForQuestions] = useState([]);

  const [activeTab, setActiveTab] = useState("quiz"); // New state for tabs

  const summaryRef = useRef(null);

  useEffect(() => {
    document.title = "Math Quiz";
    return () => {
      document.title = "Campus Goals";
    };
  }, []);

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUserId(user.uid);
      } else {
        setUserId(null);
      }
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (showScore) {
      confetti({
        particleCount: 150,
        spread: 60,
        angle: 90,
        origin: { x: 0.5, y: 0.5 },
        colors: ['#bb0000', '#ffffff'],
        scalar: 1.2,
      });
    }
  }, [showScore]);

  const getRandomQuestions = useCallback(async () => {
    try {
      const q = query(
        collection(db, "satmath"),
        where("quiztype", "==", "satmath"),
      );
      const querySnapshot = await getDocs(q);
      const questions = querySnapshot.docs.map((doc) => doc.data());
      return questions.sort(() => Math.random() - 0.5).slice(0, 20);
    } catch (error) {
      console.error("Error fetching questions:", error);
      return [];
    }
  }, []);

  const shuffleOptions = useCallback((options) => {
    return [...options].sort(() => Math.random() - 0.5);
  }, []);

  const saveQuizState = useCallback(
    async (state) => {
      if (!userId) return;
      try {
        const stateToSave = {
          ...state,
          shuffledOptionsForQuestions: shuffledOptionsForQuestions,
        };
        await setDoc(doc(db, "satmathquizStates", userId), stateToSave);
      } catch (error) {
        console.error("Error saving quiz state: ", error);
      }
    },
    [userId, shuffledOptionsForQuestions],
  );

  const loadQuizState = useCallback(async () => {
    if (!userId) return;
    try {
      const quizStateRef = doc(db, "satmathquizStates", userId);
      const quizStateSnap = await getDoc(quizStateRef);

      if (quizStateSnap.exists()) {
        const state = quizStateSnap.data();
        setSavedQuizState(state);
        setHasResumableQuiz(true);
      } else {
        setHasResumableQuiz(false);
        setSavedQuizState(null);
      }
    } catch (error) {
      console.error("Error loading quiz state:", error);
    }
  }, [userId]);

  const deleteQuizState = useCallback(async () => {
    if (!userId) return;
    try {
      await deleteDoc(doc(db, "satmathquizStates", userId));
      setHasResumableQuiz(false);
      setSavedQuizState(null);
    } catch (error) {
      console.error("Error deleting quiz state: ", error);
    }
  }, [userId]);

  const startNewQuiz = useCallback(async () => {
    setIsLoading(true);
    try {
      await deleteQuizState();
      setSavedQuizState(null);
      setHasResumableQuiz(false);

      const newQuestions = await getRandomQuestions();
      const shuffledOptionsForQuestions = newQuestions.map((q, index) => ({
        questionIndex: index,
        shuffledOptions: shuffleOptions(q.options),
      }));

      setRandomizedQuestions(newQuestions);
      setShuffledOptionsForQuestions(shuffledOptionsForQuestions);
      setCurrentQuestion(0);
      setSelectedAnswers([]);
      setShowExplanation(false);
      setAnswerStatus(null);
      setCorrectAnswers(0);
      setShowScore(false);
      setQuizSummary([]);
      setIsAnswerSubmitted(false);
      setShowWelcome(false);

      if (newQuestions.length > 0) {
        setShuffledOptions(shuffledOptionsForQuestions[0].shuffledOptions);
      }

      saveQuizState({
        randomizedQuestions: newQuestions,
        currentQuestion: 0,
        selectedAnswers: [],
        showExplanation: false,
        answerStatus: null,
        correctAnswers: 0,
        showScore: false,
        quizSummary: [],
        shuffledOptions: shuffledOptionsForQuestions[0].shuffledOptions,
        isAnswerSubmitted: false,
        shuffledOptionsForQuestions,
      });
    } catch (error) {
      console.error("Error starting new quiz:", error);
    } finally {
      setIsLoading(false);
    }
  }, [getRandomQuestions, shuffleOptions, deleteQuizState, saveQuizState]);

  const resumeQuiz = useCallback(async () => {
    setIsLoading(true);
    try {
      if (savedQuizState) {
        setRandomizedQuestions(savedQuizState.randomizedQuestions);
        setCurrentQuestion(savedQuizState.currentQuestion);
        setSelectedAnswers(savedQuizState.selectedAnswers || []);
        setShowExplanation(savedQuizState.showExplanation || false);
        setAnswerStatus(savedQuizState.answerStatus || null);
        setCorrectAnswers(savedQuizState.correctAnswers || 0);
        setShowScore(savedQuizState.showScore || false);
        setQuizSummary(savedQuizState.quizSummary || []);
        setIsAnswerSubmitted(savedQuizState.isAnswerSubmitted || false);
        setShuffledOptionsForQuestions(savedQuizState.shuffledOptionsForQuestions || []);

        const currentShuffledOptions = savedQuizState.shuffledOptionsForQuestions.find(
          (so) => so.questionIndex === savedQuizState.currentQuestion
        )?.shuffledOptions || [];
        setShuffledOptions(currentShuffledOptions);

        setShowWelcome(false);
      } else {
        console.error("No saved quiz state found or invalid state");
        await startNewQuiz();
      }
    } catch (error) {
      console.error("Error resuming quiz:", error);
      await startNewQuiz();
    } finally {
      setIsLoading(false);
    }
  }, [savedQuizState, startNewQuiz]);

  useEffect(() => {
    if (!showWelcome && randomizedQuestions.length > 0) {
      const currentShuffledOptions = shuffledOptionsForQuestions.find(
        (so) => so.questionIndex === currentQuestion
      )?.shuffledOptions || [];
      setShuffledOptions(currentShuffledOptions);

      if (isAnswerSubmitted) {
        setShowExplanation(true);
      }
    }
  }, [
    showWelcome,
    randomizedQuestions,
    currentQuestion,
    shuffledOptionsForQuestions,
    isAnswerSubmitted,
  ]);

  useEffect(() => {
    const initializeQuiz = async () => {
      setIsLoading(true);
      try {
        await loadQuizState();
        if (randomizedQuestions.length === 0) {
          const newQuestions = await getRandomQuestions();
          setRandomizedQuestions(newQuestions);
        }
      } catch (error) {
        console.error("Error initializing quiz:", error);
      } finally {
        setIsLoading(false);
      }
    };
    initializeQuiz();
  }, [loadQuizState, getRandomQuestions, randomizedQuestions.length]);

  const handleAnswerClick = useCallback(
    (answer) => {
      if (isAnswerSubmitted || showExplanation) return;
      setSelectedAnswers([answer]); // Always select only one answer
    },
    [isAnswerSubmitted, showExplanation],
  );

  const handleSubmit = () => {
    const currentQuestionData = randomizedQuestions[currentQuestion];
    const isCorrect = selectedAnswers[0] === currentQuestionData.correctAnswer;

    const userAnswerText =
      currentQuestionData.options.find(
        (option) => option.id === selectedAnswers[0],
      )?.text || "Not found";

    const correctAnswerText =
      currentQuestionData.options.find(
        (option) => option.id === currentQuestionData.correctAnswer,
      )?.text || "Not found";

    const newQuizSummary = [
      ...quizSummary,
      {
        question: currentQuestionData.text,
        userAnswer: userAnswerText,
        correctAnswer: correctAnswerText,
        isCorrect: isCorrect,
        explanation: isCorrect ? "" : currentQuestionData.explanation,
      },
    ];

    const newCorrectAnswers = isCorrect ? correctAnswers + 1 : correctAnswers;

    const newState = {
      randomizedQuestions,
      currentQuestion,
      selectedAnswers,
      showExplanation: true,
      answerStatus: isCorrect ? "correct" : "incorrect",
      correctAnswers: newCorrectAnswers,
      showScore,
      quizSummary: newQuizSummary,
      shuffledOptions,
      isAnswerSubmitted: true,
    };

    setQuizSummary(newQuizSummary);
    setShowExplanation(true);
    setIsAnswerSubmitted(true);
    setAnswerStatus(isCorrect ? "correct" : "incorrect");
    setCorrectAnswers(newCorrectAnswers);

    saveQuizState(newState);
  };

  const handleNextQuestion = async () => {
    const nextQuestion = currentQuestion + 1;
    if (nextQuestion < randomizedQuestions.length) {
      // Proceed to next question
      setCurrentQuestion(nextQuestion);
      setSelectedAnswers([]);
      setShowExplanation(false);
      setAnswerStatus(null);
      setIsAnswerSubmitted(false);

      const nextShuffledOptions = shuffledOptionsForQuestions.find(
        (so) => so.questionIndex === nextQuestion
      )?.shuffledOptions || [];

      setShuffledOptions(nextShuffledOptions);

      const newState = {
        randomizedQuestions,
        currentQuestion: nextQuestion,
        selectedAnswers: [],
        showExplanation: false,
        answerStatus: null,
        correctAnswers,
        showScore: false,
        quizSummary,
        shuffledOptions: nextShuffledOptions,
        isAnswerSubmitted: false,
        shuffledOptionsForQuestions,
      };
      saveQuizState(newState);
    } else {
      // Quiz is complete
      setShowScore(true);

      // Delete the saved quiz state as quiz is complete
      try {
        await deleteQuizState();
        setHasResumableQuiz(false);
        setSavedQuizState(null);
      } catch (error) {
        console.error("Error deleting quiz state after completion: ", error);
      }

      // Reset in-memory state variables as needed
      setCurrentQuestion(0);
      setSelectedAnswers([]);
      setShowExplanation(false);
      setAnswerStatus(null);
      setIsAnswerSubmitted(false);
      setShuffledOptions([]);
      setShuffledOptionsForQuestions([]);
      // Keep `quizSummary` and `correctAnswers` as they are used in the summary screen

      // No need to save the quiz state after completion
    }
  };

  const renderOptions = () => {
    const currentQuestionData = randomizedQuestions[currentQuestion];
    return shuffledOptions.map((option) => (
      <button
        key={option.id}
        className={`
          satmath-option-button
          ${selectedAnswers.includes(option.id) ? "selected" : ""}
          ${
            showExplanation &&
            currentQuestionData.correctAnswer === option.id
              ? "correct"
              : ""
          }
          ${
            showExplanation &&
            selectedAnswers.includes(option.id) &&
            currentQuestionData.correctAnswer !== option.id
              ? "incorrect"
              : ""
          }
        `}
        onClick={() => handleAnswerClick(option.id)}
        disabled={isAnswerSubmitted || showExplanation}
      >
        <span className="satmath-option-text">
          <MathJaxRenderer text={option.text} />
        </span>
      </button>
    ));
  };

  const handleGeneratePDF = useCallback(() => {
    generatePDF(summaryRef.current);
  }, [summaryRef]);

  const QuizSummary = ({ summary }) => (
    <div className="satmath-quiz-summary" ref={summaryRef}>
      <h3>Quiz Summary</h3>
      {summary.map((item, index) => (
        <div
          key={index}
          className={`satmath-summary-item ${
            item.isCorrect ? "correct" : "incorrect"
          }`}
        >
          <p>
            <strong>Q{index + 1}:</strong>
          </p>
          <p className="satmath-question">
            <MathJaxRenderer text={item.question} />
          </p>
          <p>
            Your answer: <MathJaxRenderer text={item.userAnswer} />
          </p>
          <p>
            Correct answer: <MathJaxRenderer text={item.correctAnswer} />
          </p>
          {!item.isCorrect && (
            <>
              <p>Explanation:</p>
              <div className="satmath-explanation">
                <MathJaxRenderer text={item.explanation} />
              </div>
            </>
          )}
        </div>
      ))}
    </div>
  );

  const restartQuiz = async () => {
    setCurrentQuestion(0);
    setCorrectAnswers(0);
    setShowScore(false);
    setSelectedAnswers([]);
    setShowExplanation(false);
    setAnswerStatus(null);
    setQuizSummary([]);
    setIsAnswerSubmitted(false);

    setShowWelcome(true);

    try {  
      await deleteQuizState();
    } catch (error) {
      console.error("Error restarting quiz: ", error);
    }
  };

  return (
    <>
      <div className="satmath-quiz-container">
        {/* Tabs */}
        <div className="satmath-tabs">
          <button
            className={`satmath-tab ${activeTab === "quiz" ? "active" : ""}`}
            onClick={() => setActiveTab("quiz")}
          >
            <Book size={18} />
            <span>Quiz</span>
          </button>
          <button
            className={`satmath-tab ${activeTab === "calculator" ? "active" : ""}`}
            onClick={() => setActiveTab("calculator")}
          >
            <CalculatorIcon size={18} />
            <span>Calculator</span>
          </button>
        </div>

        {activeTab === "quiz" ? (
          <>
            {showWelcome ? (
              <div className="satmath-welcome-screen">
                <h2 className="satmath-welcome-header">Welcome to Math Quiz</h2>
                <div className="satmath-quiz-instructions">
                    <p>
                      This quiz is designed for college-bound students preparing for the SAT Math section.
                    </p>
                    <p>
                      Each question presents you with a mathematical problem and multiple-choice answers.
                    </p>
                    <p>
                      All questions have a single correct answer. Please select the best answer for each question.
                    </p>
                    <p>
                      The quiz consists of 20 questions covering various SAT Math topics, including algebra, geometry, trigonometry, and data analysis.
                    </p>
                    <p>
                      Good luck!
                    </p>
                  </div>
                  {hasResumableQuiz && savedQuizState && (
                    <div className="satmath-saved-quiz-info">
                      <p>You have a saved quiz. Would you like to resume?</p>
                      <p>
                        Progress: {savedQuizState.currentQuestion + 1} /{" "}
                        {savedQuizState.randomizedQuestions.length} questions
                      </p>
                      <p>Current Score: {savedQuizState.correctAnswers}</p>
                      <button onClick={resumeQuiz} className="satmath-resume-button">
                        Resume Quiz
                      </button>
                    </div>
                  )}
                  <div className="satmath-welcome-button-container">
                    <button
                      className="satmath-welcome-button"
                      onClick={startNewQuiz}
                    >
                      Start New Quiz
                    </button>
                  </div>
              </div>
            ) : showScore ? (
              <div className="satmath-quiz-completion">
                <h2>Quiz Completed!</h2>
                <h3>
                    You scored {correctAnswers} out of{" "}
                    {randomizedQuestions.length}
                  </h3>
                  <QuizSummary summary={quizSummary} />
                  <div className="satmath-new-quiz-button-container">
                    <button
                      className="satmath-new-quiz-button"
                      onClick={restartQuiz}
                    >
                      Start New Quiz
                    </button>
                    <button
                      className="satmath-download-pdf-button"
                      onClick={handleGeneratePDF}
                    >
                      Download Summary PDF
                    </button>
                  </div>
              </div>
            ) : (
              <>
                <h2>Math Quiz</h2>
                <h3>
                  Question {currentQuestion + 1} of {randomizedQuestions.length}
                </h3>

                {/* Display the question text */}
                <p className="satmath-question">
                  <MathJaxRenderer
                    text={randomizedQuestions[currentQuestion].text}
                  />
                </p>

                {/* Render the ChartComponent if chartData exists */}
                {randomizedQuestions[currentQuestion].chartData && (
                  <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                    <ChartComponent
                      type={randomizedQuestions[currentQuestion].chartData.type}
                      data={randomizedQuestions[currentQuestion].chartData.data}
                      width={400}
                      height={300}
                    />
                  </div>
                )}

                {/* Instruction */}
                <p className="satmath-question-instruction">Select one option:</p>

                {/* Render options */}
                <div className="satmath-options-list">{renderOptions()}</div>

                {!isAnswerSubmitted ? (
                  <div className="satmath-button-container">
                    <button
                      onClick={handleSubmit}
                      disabled={selectedAnswers.length === 0}
                    >
                      Submit
                    </button>
                  </div>
                ) : (
                  <div className="satmath-button-container">
                    <button onClick={handleNextQuestion}>
                      {currentQuestion < randomizedQuestions.length - 1
                        ? "Next Question"
                        : "Finish Quiz"}
                    </button>
                  </div>
                )}

                {showExplanation && (
                  <div className="satmath-explanation">
                    <p className={`satmath-answer-status ${answerStatus}`}>
                      {answerStatus === "correct" ? "Correct!" : "Incorrect!"}
                    </p>
                    {answerStatus === "incorrect" && (
                      <>
                        <p className="correct-answer">
                          The correct answer is:{" "}
                          <MathJaxRenderer
                            text={
                              randomizedQuestions[currentQuestion].options.find(
                                (option) =>
                                  option.id === randomizedQuestions[currentQuestion].correctAnswer,
                              )?.text || "Not found"
                            }
                          />
                        </p>
                        <p>Explanation:</p>
                        <div className="satmath-explanation-text">
                          <MathJaxRenderer
                            text={randomizedQuestions[currentQuestion].explanation}
                          />
                        </div>
                      </>
                    )}
                  </div>
                )}
              </>
            )}
          </>
        ) : activeTab === "calculator" ? (
          <Calculator />
        ) : null}
      </div>
    </>
  );
};

export default SatMathQuiz;
