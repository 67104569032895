// generatePDF.js
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

export const generatePDF = (element) => {
  if (element) {
    const input = element;
    const originalHeight = input.style.maxHeight;
    const originalOverflow = input.style.overflow;
    const originalPadding = input.style.padding;

    input.style.maxHeight = 'none';
    input.style.overflow = 'visible';
    input.style.padding = '20px';

    html2canvas(input, {
      scrollY: -window.scrollY,
      scale: 1,
      height: input.scrollHeight + 40,
      windowHeight: input.scrollHeight + 40,
    }).then((canvas) => {
      input.style.maxHeight = originalHeight;
      input.style.overflow = originalOverflow;
      input.style.padding = originalPadding;

      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF('p', 'px', [canvas.width, canvas.height]);

      pdf.addImage(imgData, 'PNG', 0, 0, canvas.width, canvas.height);
      pdf.save('quiz_summary.pdf');
    });
  }
};