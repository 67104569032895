import React, { useState, useEffect, useRef, useCallback } from "react";
import Flashcard from "./components/Flashcard";
import SynProgressBar from "./components/SynProgressBar";
import "./synonymsQuiz.css";
import parse from 'html-react-parser';
import DOMPurify from 'dompurify';
import { collection, query, where, getDocs, doc, setDoc, getDoc } from "firebase/firestore";
import { auth, db } from "./firebase";

const QUESTIONS_PER_QUIZ = 20;

const shuffleArray = (array) => {
  const shuffled = [...array];
  for (let i = shuffled.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
  }
  return shuffled;
};

const VocabularyQuiz = () => {
  const initialSetupDone = useRef(false);
  const [words, setWords] = useState([]);
  const [currentWordIndex, setCurrentWordIndex] = useState(0);
  const [score, setScore] = useState(0);
  const [showScore, setShowScore] = useState(false);
  const [selectedAnswer, setSelectedAnswer] = useState(null);
  const [answerStatus, setAnswerStatus] = useState(null);
  const [quizSummary, setQuizSummary] = useState([]);
  const [randomizedAnswers, setRandomizedAnswers] = useState([]);
  const [showWelcome, setShowWelcome] = useState(true);
  const [quizType, setQuizType] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [savedQuizState, setSavedQuizState] = useState(null);
  const [showWarning, setShowWarning] = useState(false);
  const [scoreToUpdate, setScoreToUpdate] = useState(0);

  const selectQuestions = async (type) => {
    try {
      const q = query(collection(db, "users"), where("quiztype", "==", "synonyms"));
      const querySnapshot = await getDocs(q);

      const synonymsData = [];
      querySnapshot.forEach((doc) => {
        synonymsData.push(doc.data());
      });

      const sortedWords = synonymsData.sort((a, b) => {
        const difficultyOrder = { easy: 1, medium: 2, hard: 3 };
        return difficultyOrder[a.difficulty] - difficultyOrder[b.difficulty];
      });

      const easyWords = sortedWords.filter((word) => word.difficulty === "easy");
      const mediumWords = sortedWords.filter((word) => word.difficulty === "medium");
      const hardWords = sortedWords.filter((word) => word.difficulty === "hard");

      const easyCount = Math.floor(QUESTIONS_PER_QUIZ * 0.05);
      const mediumCount = Math.floor(QUESTIONS_PER_QUIZ * 0.55);
      const hardCount = QUESTIONS_PER_QUIZ - easyCount - mediumCount;

      const selectedEasy = shuffleArray(easyWords).slice(0, easyCount);
      const selectedMedium = shuffleArray(mediumWords).slice(0, mediumCount);
      const selectedHard = shuffleArray(hardWords).slice(0, hardCount);

      const selectedWords = [...selectedEasy, ...selectedMedium, ...selectedHard];

      return selectedWords
        .slice(0, QUESTIONS_PER_QUIZ)
        .map((word) => {
          if (type === "synonyms" && (!word.synonyms || !word.syn_correct_answer || !word.syn_explanation)) {
            console.error(`Missing synonym data for word: ${word.word}`);
            return null;
          }
          if (type === "antonyms" && (!word.antonyms || !word.ant_correct_answer || !word.ant_explanation)) {
            console.error(`Missing antonym data for word: ${word.word}`);
            return null;
          }
          return {
            ...word,
            options: type === "synonyms" ? word.synonyms : word.antonyms,
            correctAnswer: type === "synonyms" ? word.syn_correct_answer : word.ant_correct_answer,
            explanation: type === "synonyms" ? word.syn_explanation : word.ant_explanation,
          };
        })
        .filter((word) => word !== null);
    } catch (error) {
      console.error("Error fetching synonyms data from Firestore:", error);
      return [];
    }
  };

  useEffect(() => {
    document.title = "Quick Quiz";
    const fetchData = async () => {
      await loadQuizState();
      setIsLoading(false);
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (!showWelcome && quizType && !initialSetupDone.current) {
      const fetchData = async () => {
        const selectedWords = await selectQuestions(quizType);
        setWords(selectedWords);
        setRandomizedAnswers(shuffleArray(selectedWords[0]?.options || []));
        initialSetupDone.current = true;
      };
      fetchData();
    }
  }, [showWelcome, quizType]);

  const saveQuizState = useCallback(async () => {
    if (!auth.currentUser) return;

    const quizState = {
      words,
      currentWordIndex,
      score,
      showScore,
      quizSummary,
      quizType,
      timestamp: new Date().toISOString(),
    };

    try {
      await setDoc(doc(db, "quizStates", auth.currentUser.uid), quizState);
      console.log("Quiz state saved successfully");
    } catch (error) {
      console.error("Error saving quiz state:", error);
    }
  }, [words, currentWordIndex, score, showScore, quizSummary, quizType]);

  useEffect(() => {
    if (auth.currentUser && !isLoading) {
      saveQuizState();
    }
  }, [words, currentWordIndex, score, showScore, quizSummary, quizType, isLoading, saveQuizState]);

  const startNewQuiz = async (type) => {
    if (savedQuizState && savedQuizState.words && savedQuizState.words.length > 0) {
      setShowWarning(true);
      return;
    }
    const selectedWords = await selectQuestions(type);
    setWords(selectedWords);
    setCurrentWordIndex(0);
    setScore(0);
    setShowScore(false);
    setQuizSummary([]);
    setQuizType(type);
    setRandomizedAnswers(shuffleArray(selectedWords[0]?.options || []));
    setShowWelcome(false);
    initialSetupDone.current = true;
    setShowWarning(false);
  };

  const resumeQuiz = () => {
    if (savedQuizState) {
      setWords(savedQuizState.words);
      setCurrentWordIndex(savedQuizState.currentWordIndex);
      setScore(savedQuizState.score);
      setShowScore(savedQuizState.showScore);
      setQuizSummary(savedQuizState.quizSummary);
      setQuizType(savedQuizState.quizType);
      setShowWelcome(false);
      initialSetupDone.current = true;

      if (savedQuizState.currentWordIndex < savedQuizState.words.length) {
        setRandomizedAnswers(
          shuffleArray(savedQuizState.words[savedQuizState.currentWordIndex].options)
        );
      }
    }
  };

  const handleAnswerClick = (answer) => {
    setSelectedAnswer(answer);
    const currentWord = words[currentWordIndex];
    const isCorrect = answer === currentWord.correctAnswer;

    setQuizSummary([
      ...quizSummary,
      {
        word: currentWord.word,
        userAnswer: answer,
        correctAnswer: currentWord.correctAnswer,
        isCorrect: isCorrect,
        explanation: currentWord.explanation,
      },
    ]);

    setAnswerStatus(isCorrect ? "correct" : "incorrect");

    if (isCorrect) {
      setScoreToUpdate((prev) => prev + 1);
    }
  };

  const handleNextQuestion = () => {
    const nextQuestion = currentWordIndex + 1;
    if (nextQuestion < words.length) {
      setCurrentWordIndex(nextQuestion);
      setSelectedAnswer(null);
      setAnswerStatus(null);
      setRandomizedAnswers(shuffleArray(words[nextQuestion].options));
    } else {
      setShowScore(true);
    }

    if (scoreToUpdate > 0) {
      setScore((prevScore) => prevScore + scoreToUpdate);
      setScoreToUpdate(0);
    }
  };

  const restartQuiz = () => {
    setShowWelcome(true);
    setQuizType(null);
    setWords([]);
    setCurrentWordIndex(0);
    setScore(0);
    setShowScore(false);
    setSelectedAnswer(null);
    setAnswerStatus(null);
    setQuizSummary([]);
    setRandomizedAnswers([]);
    initialSetupDone.current = false;
    setSavedQuizState(null);
    setShowWarning(false);
  };

  const loadQuizState = async () => {
    setIsLoading(true);
    if (!auth.currentUser) {
      setIsLoading(false);
      return;
    }

    try {
      const docRef = doc(db, "quizStates", auth.currentUser.uid);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const data = docSnap.data();
        setSavedQuizState(data);
        setWords(data.words);
        setCurrentWordIndex(data.currentWordIndex);
        setScore(data.score);
        setShowScore(data.showScore);
        setQuizSummary(data.quizSummary);
        setQuizType(data.quizType);
        setShowWelcome(true);
        initialSetupDone.current = true;
        console.log("Loaded saved quiz state:", data);
      } else {
        console.log("No saved quiz state found");
      }
    } catch (error) {
      console.error("Error loading quiz state:", error);
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return <div className="syn-quiz-container">Loading...</div>;
  }

  if (showWelcome) {
    return (
      <div className="syn-quiz-container">
        <div className="syn-welcome-screen">
          <h2>Welcome to Quick Quiz</h2>
          <div className="syn-quiz-instructions">
              <p>
                  This quiz is designed to test your knowledge of synonyms and
                  antonyms for various words.
              </p>
              <p>
                  For each question, you'll be presented with a word and multiple
                  options. Your task is to select the correct synonym or antonym for
                  the given word, depending on the quiz type you choose.
              </p>
              <p>
                  The quiz consists of {QUESTIONS_PER_QUIZ} questions, with a mix of
                  easy, medium, and hard difficulty levels.
              </p>
              <p>
                  After selecting an answer, you'll see if you're correct or not, and you can click the "Next" button to move to the next question at your own pace.
              </p>
              <p>
                  At the end of the quiz, you'll see your score and a summary of all
                  your answers.
              </p>
              <p>Good luck and enjoy expanding your vocabulary!</p>
          </div>
          {savedQuizState &&
            savedQuizState.quizType &&
            savedQuizState.words &&
            savedQuizState.words.length > 0 && (
              <div className="syn-saved-quiz-info">
                <h3>You have a saved quiz:</h3>
                <p>Quiz Type: {savedQuizState.quizType}</p>
                <p>
                  Progress: {savedQuizState.currentWordIndex} /{" "}
                  {savedQuizState.words.length} words
                </p>
                <p>Current Score: {savedQuizState.score}</p>
                <button className="syn-resume-button" onClick={resumeQuiz}>
                  Resume Quiz
                </button>
              </div>
            )}
          <div className="syn-quiz-type-selection">
            <h3>Start a New Quiz:</h3>
            <div className="syn-quiz-type-buttons">
              <button
                className="syn-quiz-type-button synonyms"
                onClick={() => startNewQuiz("synonyms")}
              >
                Synonyms Quiz
              </button>
              <button
                className="syn-quiz-type-button antonyms"
                onClick={() => startNewQuiz("antonyms")}
              >
                Antonyms Quiz
              </button>
            </div>
            {showWarning && (
              <p style={{ color: "red", textAlign: "center" }}>
                A new quiz cannot be started without completing the current
                quiz!
              </p>
            )}
          </div>
        </div>
      </div>
    );
  }

  if (words.length === 0) {
    return <div className="syn-quiz-container">Loading...</div>;
  }

  return (
    <div className="syn-quiz-container">
      {showScore ? (
        <div className="syn-quiz-completion">
          <h2>
            {quizType.charAt(0).toUpperCase() + quizType.slice(1)} Quiz
            Completed!
          </h2>
          <h3>
            You scored {score} out of {words.length}
          </h3>
          <div className="syn-quiz-summary">
            <h3>Quiz Summary</h3>
            {quizSummary.map((item, index) => (
              <div
                key={index}
                className={`syn-summary-item ${item.isCorrect ? "correct" : "incorrect"}`}
              >
                <p>
                  <strong>Word:</strong> {item.word}
                </p>
                <p>Your answer: {item.userAnswer}</p>
                <p>Correct answer: {item.correctAnswer}</p>
                {!item.isCorrect && (
                  <div>
                    <strong>Explanation:</strong>
                    {item.explanation.split('\n\n').map((paragraph, idx) => (
                      <p key={idx}>{parse(DOMPurify.sanitize(paragraph.replace(/\n/g, '<br/>')))}</p>
                    ))}
                  </div>
                )}
              </div>
            ))}
          </div>
          <div className="syn-button-container">
            <button className="syn-restart-button" onClick={restartQuiz}>
              Restart Quiz
            </button>
          </div>
        </div>
      ) : (
        <>
          <div className="syn-quiz-header">
            <span>{quizType.charAt(0).toUpperCase() + quizType.slice(1)} Quiz</span>
            <div className="syn-quiz-info">
              <span>{currentWordIndex + 1} of {words.length}</span>
              <span>Score: {score}</span>
            </div>
          </div>
          <SynProgressBar score={currentWordIndex} total={words.length} />
          <Flashcard
            word={words[currentWordIndex]}
            onAnswer={handleAnswerClick}
            selectedAnswer={selectedAnswer}
            answerStatus={answerStatus}
            randomizedAnswers={randomizedAnswers}
            quizType={quizType}
          />
          <div className="syn-button-container">
            <button
              className="syn-next-button"
              onClick={handleNextQuestion}
              disabled={!selectedAnswer}
            >
              {currentWordIndex === words.length - 1 ? "Finish Quiz" : "Next"}
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default VocabularyQuiz;