import React from "react";

const TableComponent = ({ data }) => {
  if (!data || !data.headers || !data.rows) {
    return <div>Invalid table data</div>;
  }

  const cellStyle = {
    border: '1px solid black',
    padding: '8px',
    textAlign: 'left !important'
  };

  return (
    <table style={{ width: '100%', borderCollapse: 'collapse', marginBottom: '20px' }}>
      <caption style={{ fontWeight: 'bold', marginBottom: '10px', textAlign: 'center' }}>{data.title}</caption>
      <thead>
        <tr>
          {data.headers.map((header, index) => (
            <th key={index} style={{ ...cellStyle, backgroundColor: '#f2f2f2' }}>{header}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {data.rows.map((row, rowIndex) => (
          <tr key={rowIndex}>
            {data.headers.map((header, cellIndex) => (
              <td key={cellIndex} style={cellStyle}>{row[header]}</td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default TableComponent;