import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import leftImageSrc from './assets/campusgoals-website-left-image.png';
import rightImageSrc from './assets/campusgoals-website-right-image.png';

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  min-height: 100vh;
  background-color: #f9f9f9;
  padding: 0;
  box-sizing: border-box;

  /* Conditionally apply background images if imagesLoaded is true */
  ${props =>
    props.imagesLoaded &&
    `
    background-image: url('${process.env.PUBLIC_URL}${leftImageSrc}'), url('${process.env.PUBLIC_URL}${rightImageSrc}');
    background-repeat: no-repeat;
    background-position: left top, right top;
    background-size: 350px auto, 350px auto;
  `}

  /* Transition for smooth background appearance */
  transition: background-size 0.3s ease-in-out, background-image 0.3s ease-in-out;

  /* Responsive adjustments using calc() for gradual size reduction */
  @media (max-width: 1200px) {
    ${props =>
      props.imagesLoaded &&
      `
      background-size: calc(120px + (350 - 120) * ((100vw - 768px) / (1200 - 768))) auto,
                       calc(120px + (350 - 120) * ((100vw - 768px) / (1200 - 768))) auto;
    `}
  }

  @media (max-width: 992px) {
    ${props =>
      props.imagesLoaded &&
      `
      background-size: calc(100px + (120 - 100) * ((100vw - 768px) / (992 - 768))) auto,
                       calc(100px + (120 - 100) * ((100vw - 768px) / (992 - 768))) auto;
    `}
  }

  @media (max-width: 768px) {
    background-image: none; /* Hide images on smaller screens */
  }
`;

const Content = styled.div`
  position: relative;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  background-color: #ffffff;
  padding: 0;
  z-index: 1;

  @media (max-width: 1024px) {
    max-width: 90%;
  }
`;

const ContentWrapper = ({ children }) => {
  const [imagesLoaded, setImagesLoaded] = useState(false);

  useEffect(() => {
    const leftImg = new Image();
    const rightImg = new Image();

    let loadedCount = 0;

    const handleImageLoad = () => {
      loadedCount += 1;
      if (loadedCount === 2) {
        setImagesLoaded(true);
      }
    };

    const handleImageError = (e) => {
      console.error(`Failed to load image: ${e.target.src}`);
      loadedCount += 1;
      if (loadedCount === 2) {
        setImagesLoaded(true);
      }
    };

    leftImg.src = `${process.env.PUBLIC_URL}${leftImageSrc}`;
    rightImg.src = `${process.env.PUBLIC_URL}${rightImageSrc}`;

    leftImg.onload = handleImageLoad;
    rightImg.onload = handleImageLoad;

    leftImg.onerror = handleImageError;
    rightImg.onerror = handleImageError;

    // Cleanup event listeners on unmount
    return () => {
      leftImg.onload = null;
      rightImg.onload = null;
      leftImg.onerror = null;
      rightImg.onerror = null;
    };
  }, []);

  return (
    <Wrapper imagesLoaded={imagesLoaded}>
      <Content>{children}</Content>
    </Wrapper>
  );
};

export default ContentWrapper;