// src/Home.js

import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckSquare, faToggleOn, faPencilSquare, faTrophy, faSortNumericAsc, faComment } from '@fortawesome/free-solid-svg-icons';
import "./Home.css";

const Home = () => {

  const [currentDescription, setCurrentDescription] = useState("Hover over a card to see its description");
  
  useEffect(() => {
    document.title = "Campus Goals Home - Free GRE & SAT Practice Quizzes";
    return () => {
      document.title = "Campus Goals";
    };
  }, []);

  const handleCardHover = (description) => {
    setCurrentDescription(description);
  };

  const cardData = [
    { title: "SAT READING/WRITING MODULE", icon: faCheckSquare, gradient: "gr-1", link: "/satrwQuiz", description: "Practice SAT reading and writing questions" },
    { title: "SYNONYMS/ANTONYMS QUIZ", icon: faToggleOn, gradient: "gr-2", link: "/synonymsQuiz", description: "Enhance your vocabulary with synonym and antonym exercises" },
    { title: "VOCABULARY BUILDER", icon: faTrophy, gradient: "gr-3", link: "/vocabularyBuilder", description: "Expand your vocabulary through interactive exercises" },
    { title: "VOCABULARY QUIZ", icon: faPencilSquare, gradient: "gr-4", link: "/quiz", description: "Test your vocabulary knowledge" },
    { title: "GRE MATH QUIZ", icon: faSortNumericAsc, gradient: "gr-5", link: "/mathQuiz", description: "Practice GRE-style math problems" },
    { title: "GRE TEXT COMPLETION QUIZ", icon: faComment, gradient: "gr-6", link: "/tcseQuiz", description: "Improve your sentence completion skills for GRE" }
  ];

  return (
    <div className="home-wrapper">
      <div className="home-main">
        <h2 className="home-header">Welcome to Campus Goals</h2>
        <p className="home-description">
          Boost your GRE and SAT preparation with our free, comprehensive quiz collection. 
          Explore various question types commonly found in these standardized tests, all at no cost. 
          Click on a card below to start practicing and improving your skills in that specific format. 
          Enhance your test readiness without spending a dime!
        </p>

        <div className="description-section">
          <p>{currentDescription}</p>
        </div>

        <Container className="card-container" style={{ maxWidth: '800px' }}>
          <Row>
            {cardData.map((card, index) => (
              <Col md={6} lg={6} className="column" key={index}>
                <Link 
                  to={card.link} 
                  className="card-link"
                  onMouseEnter={() => handleCardHover(card.description)}
                  onMouseLeave={() => handleCardHover("Hover over a card to see its description")}
                >
                  <Card className={`${card.gradient} card`}>
                    <Card.Body>
                      <div className="txt">
                        <h1>{card.title}</h1>
                      </div>
                      <div className="ico-card">
                        <FontAwesomeIcon icon={card.icon} />
                      </div>
                    </Card.Body>
                  </Card>
                </Link>
              </Col>
            ))}
          </Row>
        </Container>

      </div>
    </div>
  );
};

export default Home;