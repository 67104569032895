// src/components/blog/BlogList.js
import React from 'react';
import { Link } from 'react-router-dom';
import posts from '../../posts/postsMetadata'; // Adjust the path as needed
import './BlogList.css';

function BlogList() {
  return (
    <div className="blog-list-wrapper">
      <div className="blog-list-main">
        <h1 className="blog-list-header">Blog</h1>
        {posts.map((post) => (
          <div key={post.slug} className="blog-list-item">
            <h2 className="blog-list-item-title">
              <Link to={`/blog/${post.slug}`} className="blog-list-link">
                {post.title}
              </Link>
            </h2>
            <p className="blog-list-item-date">{post.date}</p>
          </div>
        ))}
      </div>
    </div>
  );
}

export default BlogList;