import React from 'react';
import { MathJax, MathJaxContext } from 'better-react-mathjax';
import './MathJaxRenderer.css';

const MathJaxRenderer = ({ text }) => {
  const mathJaxConfig = {
    tex: {
      inlineMath: [['$', '$'], ['\\(', '\\)']],
    },
    svg: {
      fontCache: 'global',
      scale: 1.2,
    },
  };

  const renderTextWithLineBreaks = (text) => {
    // First, split the text into paragraphs using double newlines
    const paragraphs = text.split('\n\n');

    return paragraphs.map((paragraph, idx) => {
      // Replace single newlines within paragraphs with <br />
      const lines = paragraph.split('\n').map((line, lineIdx) => (
        <React.Fragment key={lineIdx}>
          <MathJax inline dynamic>
            {line}
          </MathJax>
          {/* Add <br /> if not the last line */}
          {lineIdx !== paragraph.split('\n').length - 1 && <br />}
        </React.Fragment>
      ));
      return <p key={idx}>{lines}</p>;
    });
  };

  return (
    <MathJaxContext config={mathJaxConfig}>
      <div className="mathjax-renderer">
        {renderTextWithLineBreaks(text)}
      </div>
    </MathJaxContext>
  );
};

export default MathJaxRenderer;