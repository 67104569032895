import React, { useState, useEffect, useCallback } from "react";
import { ChartComponent } from "./ChartComponent"; // Adjust the path as necessary
import "./satrwQuiz.css";
import {
  collection,
  query,
  where,
  getDocs,
  doc,
  setDoc,
  getDoc,
  deleteDoc,
} from "firebase/firestore";
import { db } from "./firebase";
import { getAuth } from "firebase/auth"; // Import Firebase Authentication

const SatrwQuiz = () => {
  const [questions, setQuestions] = useState([]);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [selectedAnswers, setSelectedAnswers] = useState({});
  const [showExplanation, setShowExplanation] = useState(false);
  const [answerStatus, setAnswerStatus] = useState(null);
  const [correctAnswers, setCorrectAnswers] = useState(0);
  const [showScore, setShowScore] = useState(false);
  const [quizSummary, setQuizSummary] = useState([]);
  const [shuffledOptions, setShuffledOptions] = useState([]);
  const [isAnswerSubmitted, setIsAnswerSubmitted] = useState(false);
  const [showWelcome, setShowWelcome] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [hasIncompleteQuiz, setHasIncompleteQuiz] = useState(false);
  const [savedQuizState, setSavedQuizState] = useState(null);
  const numberOfQuestions = 20;

  const auth = getAuth();
  const userId = auth.currentUser ? auth.currentUser.uid : null;

  useEffect(() => {
    document.title = "SAT Reading and Writing Quiz";
    return () => {
      document.title = "Campus Goals";
    };
  }, []);

  const convertTextToJsx = (text) => {
    if (!text) return null;

    // First, replace all <br/> tags with \n
    const normalizedText = text.replace(/<br\s*\/?>/gi, "\n");

    // Split the text by newlines and <u> tags
    const parts = normalizedText.split(/(\n|<u>|<\/u>)/);

    return parts.map((part, index) => {
      if (part === "\n") {
        return <br key={index} />;
      } else if (part === "<u>") {
        return null; // Opening tag, we'll handle it in the next iteration
      } else if (part === "</u>") {
        return null; // Closing tag, we've already handled it
      } else {
        // Check if this part should be underlined
        const isUnderlined =
          index > 0 &&
          parts[index - 1] === "<u>" &&
          index < parts.length - 1 &&
          parts[index + 1] === "</u>";

        return isUnderlined ? <u key={index}>{part}</u> : part;
      }
    });
  };

  const getRandomQuestions = useCallback(async (numQuestions) => {
    try {
      const q = query(
        collection(db, "users"),
        where("quiztype", "==", "rwcomprehension"),
      );
      const querySnapshot = await getDocs(q);
      const data = querySnapshot.docs.map((doc) => doc.data());
      console.log("Fetched questions:", data);
      return data.sort(() => Math.random() - 0.5).slice(0, numQuestions);
    } catch (error) {
      console.error("Error fetching questions:", error);
      return [];
    }
  }, []);

  const shuffleOptions = useCallback((options) => {
    return Object.values(options).sort(() => Math.random() - 0.5);
  }, []);

  const saveQuizState = useCallback(
    async (state) => {
      if (!userId) return; // Ensure user is logged in
      try {
        await setDoc(doc(db, "satrwquizStates", userId), state);
      } catch (error) {
        console.error("Error saving quiz state: ", error);
      }
    },
    [userId],
  );

  const loadQuizState = useCallback(async () => {
    if (!userId) return; // Ensure user is logged in
    try {
      const quizStateRef = doc(db, "satrwquizStates", userId);
      const quizStateSnap = await getDoc(quizStateRef);
      if (quizStateSnap.exists()) {
        const state = quizStateSnap.data();
        setSavedQuizState(state);
        setHasIncompleteQuiz(true);
      } else {
        setHasIncompleteQuiz(false);
        setSavedQuizState(null);
      }
    } catch (error) {
      console.error("Error loading quiz state: ", error);
    }
  }, [userId]);

  const deleteQuizState = useCallback(async () => {
    if (!userId) return; // Ensure user is logged in
    try {
      await deleteDoc(doc(db, "satrwquizStates", userId));
      setHasIncompleteQuiz(false);
      setSavedQuizState(null);
    } catch (error) {
      console.error("Error deleting quiz state: ", error);
    }
  }, [userId]);

  const startNewQuiz = useCallback(async () => {
    setIsLoading(true);
    try {
      await deleteQuizState();
      const newQuestions = await getRandomQuestions(numberOfQuestions);
      setQuestions(newQuestions);
      setCurrentQuestionIndex(0);
      setSelectedAnswers({});
      setShowExplanation(false);
      setAnswerStatus(null);
      setCorrectAnswers(0);
      setShowScore(false);
      setQuizSummary([]);
      setIsAnswerSubmitted(false);
      setShowWelcome(false);
      if (newQuestions.length > 0) {
        const shuffled = shuffleOptions(newQuestions[0].options);
        setShuffledOptions(shuffled);
      }
    } catch (error) {
      console.error("Error starting new quiz:", error);
    } finally {
      setIsLoading(false);
    }
  }, [getRandomQuestions, shuffleOptions, deleteQuizState, numberOfQuestions]);

  const resumeQuiz = useCallback(async () => {
    setIsLoading(true);
    try {
      if (savedQuizState && savedQuizState.questions) {
        console.log("Saved quiz state:", savedQuizState);
        setQuestions(savedQuizState.questions);
        setCurrentQuestionIndex(savedQuizState.currentQuestionIndex);
        setSelectedAnswers(savedQuizState.selectedAnswers || {});
        setShowExplanation(savedQuizState.showExplanation || false);
        setAnswerStatus(savedQuizState.answerStatus || null);
        setCorrectAnswers(savedQuizState.correctAnswers || 0);
        setShowScore(savedQuizState.showScore || false);
        setQuizSummary(savedQuizState.quizSummary || []);
        setIsAnswerSubmitted(savedQuizState.isAnswerSubmitted || false);
        // Ensure shuffled options are set correctly when resuming
        setShuffledOptions(
          savedQuizState.shuffledOptions ||
            shuffleOptions(
              savedQuizState.questions[savedQuizState.currentQuestionIndex]
                .options,
            ),
        );
        setShowWelcome(false);
      } else {
        console.error("No saved quiz state found or invalid state");
        await startNewQuiz();
      }
    } catch (error) {
      console.error("Error resuming quiz:", error);
      await startNewQuiz();
    } finally {
      setIsLoading(false);
    }
  }, [savedQuizState, shuffleOptions, startNewQuiz]);

  useEffect(() => {
    const initializeQuiz = async () => {
      setIsLoading(true);
      try {
        await loadQuizState();
      } catch (error) {
        console.error("Error initializing quiz:", error);
      } finally {
        setIsLoading(false);
      }
    };
    initializeQuiz();
  }, [loadQuizState]);

  const handleAnswerClick = useCallback((answer) => {
    setSelectedAnswers({ [answer]: true });
  }, []);

  const isSubmitEnabled = useCallback(() => {
    return Object.keys(selectedAnswers).length === 1;
  }, [selectedAnswers]);

  const handleSubmit = () => {
    const currentQuestionData = questions[currentQuestionIndex];
    const userAnswer = Object.keys(selectedAnswers)[0];
    const isCorrect = userAnswer === currentQuestionData.answer;

    const newQuizSummary = [
      ...quizSummary,
      {
        question: currentQuestionData.question,
        userAnswer: userAnswer,
        correctAnswer: currentQuestionData.answer,
        explanation: currentQuestionData.explanation,
        isCorrect: isCorrect,
      },
    ];

    setQuizSummary(newQuizSummary);
    setShowExplanation(true);
    setIsAnswerSubmitted(true);
    setAnswerStatus(isCorrect ? "correct" : "incorrect");
    const newCorrectAnswers = isCorrect ? correctAnswers + 1 : correctAnswers;
    setCorrectAnswers(newCorrectAnswers);

    saveQuizState({
      questions,
      currentQuestionIndex,
      selectedAnswers,
      showExplanation: true,
      answerStatus: isCorrect ? "correct" : "incorrect",
      correctAnswers: newCorrectAnswers,
      showScore,
      quizSummary: newQuizSummary,
      shuffledOptions,
      isAnswerSubmitted: true,
    });
  };

  const handleNextQuestion = () => {
    const nextQuestionIndex = currentQuestionIndex + 1;
    if (nextQuestionIndex >= questions.length) {
      setShowScore(true);
    } else {
      setCurrentQuestionIndex(nextQuestionIndex);
      setSelectedAnswers({});
      setShowExplanation(false);
      setAnswerStatus(null);
      setIsAnswerSubmitted(false);
      const shuffled = shuffleOptions(questions[nextQuestionIndex].options);
      setShuffledOptions(shuffled);
    }
    saveQuizState({
      questions,
      currentQuestionIndex: nextQuestionIndex,
      selectedAnswers: {},
      showExplanation: false,
      answerStatus: null,
      correctAnswers,
      showScore: nextQuestionIndex >= questions.length,
      quizSummary,
      shuffledOptions:
        nextQuestionIndex < questions.length
          ? shuffleOptions(questions[nextQuestionIndex].options)
          : [],
      isAnswerSubmitted: false,
    });
  };

  const restartQuiz = async () => {
    await deleteQuizState();
    setShowWelcome(true);
    setShowScore(false);
    setCurrentQuestionIndex(0);
    setSelectedAnswers({});
    setShowExplanation(false);
    setAnswerStatus(null);
    setCorrectAnswers(0);
    setQuizSummary([]);
    setIsAnswerSubmitted(false);
  };

  const renderOptions = () => {
    const currentQuestionData = questions[currentQuestionIndex];
    if (!currentQuestionData || !shuffledOptions.length) {
      console.error("No question data or options available");
      return null;
    }

    return shuffledOptions.map((value, index) => {
      const label = String.fromCharCode(65 + index);
      return (
        <li key={label}>
          <label
            className={`satrw-option-label ${
              selectedAnswers[value] ? "selected" : ""
            } ${
              showExplanation
                ? currentQuestionData.answer === value
                  ? "correct"
                  : "incorrect"
                : ""
            }`}
          >
            <input
              type="radio"
              name="answer"
              value={value}
              checked={selectedAnswers[value] || false}
              onChange={() => handleAnswerClick(value)}
              disabled={isAnswerSubmitted}
            />
            <span className="satrw-option-text">
              {label}. {value}
            </span>
          </label>
        </li>
      );
    });
  };

  const QuizSummary = ({ summary }) => (
    <div className="quiz-summary">
      <h2>Quiz Summary</h2>
      {summary.map((item, index) => (
        <div
          key={index}
          className={`summary-item ${item.isCorrect ? "correct" : "incorrect"}`}
        >
          <p>
            <strong>Q{index + 1}:</strong> {item.question}
          </p>
          <p>Your answer: {item.userAnswer}</p>
          <p>Correct answer: {item.correctAnswer}</p>
          <p>Explanation: {item.explanation}</p>
        </div>
      ))}
    </div>
  );

  const renderQuestionContent = () => {
    const currentQuestionData = questions[currentQuestionIndex];
    if (!currentQuestionData) {
      return <div>No question data available</div>;
    }

    if (isLoading) {
      return <div className="satrw-quiz-container">Loading...</div>;
    }

    const { question, chartData } = currentQuestionData;

    return (
      <div>
        {chartData && (
          <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
        <ChartComponent
            type={chartData.type}
            data={chartData.data}
            width={400}
            height={300}
          />
          </div>
        )}
        <p className="satrw-question-text">{convertTextToJsx(question)}</p>
      </div>
    );
  };

  return (
    <div className="satrw-quiz-container">
      {showWelcome ? (
        <div className="satrw-welcome-screen">
          <h2 className="satrw-welcome-header">
            Welcome to SAT Reading and Writing Quiz
          </h2>
          <div className="satrw-quiz-instructions">
            <p>
              Welcome to the Reading and Writing Skills Assessment. This quiz
              evaluates your ability to comprehend and analyze diverse passages
              on contemporary subjects.
            </p>
            <p>
              You'll encounter {numberOfQuestions} questions, each accompanied
              by one or more passages. These passages may include tables or
              graphs. Every question offers four possible answers, but only one
              is the best choice.
            </p>
            <p>
              This assessment will challenge various aspects of your reading
              comprehension and analytical skills, including:
            </p>
            <ul>
              <li>
                Comprehending the central theme and key points of each passage
              </li>
              <li>Deciphering the author's perspective and intentions</li>
              <li>Drawing logical conclusions from the provided information</li>
              <li>
                Identifying crucial details and understanding their context
              </li>
              <li>
                Evaluating the structure and reasoning of presented arguments
              </li>
            </ul>
            <p>
              It's crucial to carefully read each passage and question before
              selecting your answer. Consider all options thoroughly to
              determine the most appropriate response.
            </p>
            <p>
              This quiz is designed to test your critical thinking and
              analytical abilities. Approach each question thoughtfully, and
              best of luck with your assessment!
            </p>
          </div>
          <div className="satrw-welcome-button-container">
            {hasIncompleteQuiz && savedQuizState && (
              <div className="satrw-saved-quiz-info">
                <p>You have a saved quiz. Would you like to resume?</p>
                <p>
                  Progress: {savedQuizState.currentQuestionIndex + 1} /{" "}
                  {numberOfQuestions}
                  questions
                </p>
                <p>Current Score: {savedQuizState.correctAnswers}</p>
                <button onClick={resumeQuiz} className="satrw-resume-button">
                  Resume Quiz
                </button>
              </div>
            )}
            <button className="satrw-welcome-button" onClick={startNewQuiz}>
              Start A New Quiz
            </button>
          </div>
        </div>
      ) : showScore ? (
        <>
          <h2>Quiz Completed!</h2>
          <h3>
            You scored {correctAnswers} out of {numberOfQuestions}
          </h3>
          <QuizSummary summary={quizSummary} />
          <div className="satrw-new-quiz-button-container">
            <button className="satrw-new-quiz-button" onClick={restartQuiz}>
              Restart Quiz
            </button>
          </div>
        </>
      ) : (
        <>
          {questions.length > 0 && (
            <div className="question-container">
              <h2>
                Question {currentQuestionIndex + 1} of {numberOfQuestions}
              </h2>
              {renderQuestionContent()}
              <ul className="satrw-options-list">
                {renderOptions()}
              </ul>
              {!isAnswerSubmitted && (
                <div className="satrw-button-container">
                  <button onClick={handleSubmit} disabled={!isSubmitEnabled()}>
                    Submit
                  </button>
                </div>
              )}
              {showExplanation && (
                <div className="satrw-explanation">
                  <p className={`satrw-answer-status ${answerStatus}`}>
                    {answerStatus === "correct" ? "Correct!" : "Incorrect!"}
                  </p>
                  <p>
                    {convertTextToJsx(
                      questions[currentQuestionIndex].explanation,
                    )}
                  </p>
                </div>
              )}
              {isAnswerSubmitted && (
                <div className="satrw-button-container">
                  <button onClick={handleNextQuestion}>
                    {currentQuestionIndex < questions.length - 1
                      ? "Next Question"
                      : "Finish Quiz"}
                  </button>
                </div>
              )}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default SatrwQuiz;
