import React, { useState, useEffect, useCallback } from 'react';
import './tcseQuiz.css';
import { db } from './firebase';
import { doc, setDoc, getDoc, collection, query, where, getDocs, deleteDoc } from 'firebase/firestore';
import { getAuth, onAuthStateChanged } from 'firebase/auth';

const shuffleArray = (array) => {
  const shuffled = [...array];
  for (let i = shuffled.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
  }
  return shuffled;
};

const groupShuffleOptions = (options) => {
  const groups = [
    ['A', 'B', 'C'],
    ['D', 'E', 'F'],
    ['G', 'H', 'I']
  ];

  const shuffledOptions = {};
  groups.forEach(group => {
    const groupOptions = group.map(key => options[key]).filter(Boolean);
    const shuffledGroup = shuffleArray(groupOptions);
    group.forEach((key, index) => {
      if (shuffledGroup[index]) {
        shuffledOptions[key] = shuffledGroup[index];
      }
    });
  });

  return shuffledOptions;
};

const sortOptionsAlphabetically = (options) => {
  return Object.fromEntries(
    Object.entries(options)
      .sort((a, b) => `${a[0]}: ${a[1]}`.localeCompare(`${b[0]}: ${b[1]}`))
  );
};

const sortQuestionOptions = (questions) => {
  return {
    ...questions,
    data: questions.data.map(question => ({
      ...question,
      options: sortOptionsAlphabetically(question.options)
    }))
  };
};

const TcseQuiz = () => {
  const [questions, setQuestions] = useState({ key: 0, data: [] });
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [correctAnswers, setCorrectAnswers] = useState(0);
  const [showScore, setShowScore] = useState(false);
  const [selectedAnswers, setSelectedAnswers] = useState({
    column1: null,
    column2: null,
    column3: null
  });
  const [showExplanation, setShowExplanation] = useState(false);
  const [answerStatus, setAnswerStatus] = useState(null);
  const [quizSummary, setQuizSummary] = useState([]);
  const [isAnswerSubmitted, setIsAnswerSubmitted] = useState(false);
  const [showWelcome, setShowWelcome] = useState(true);
  const [loading, setLoading] = useState(true);
  const [hasResumableQuiz, setHasResumableQuiz] = useState(false);
  const [savedQuizState, setSavedQuizState] = useState(null);
  const [userId, setUserId] = useState(null);

  useEffect(() => {
    document.title = "Text Completion and Sentence Equivalence Quiz";
    return () => {
      document.title = "Campus Goals";
    };
  }, []);

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUserId(user.uid);
      } else {
        setUserId(null);
      }
    });

    return () => unsubscribe();
  }, []);

  const saveQuizState = useCallback(async (state) => {
    if (!userId) return;
    try {
      const stateWithSortedOptions = {
        ...state,
        questions: sortQuestionOptions(state.questions)
      };
      await setDoc(doc(db, 'tcsequizStates', userId), stateWithSortedOptions);
    } catch (error) {
      console.error('Error saving quiz state: ', error);
    }
  }, [userId]);

  const loadQuizState = useCallback(async () => {
    if (!userId) return;
    try {
      const quizStateRef = doc(db, 'tcsequizStates', userId);
      const quizStateSnap = await getDoc(quizStateRef);

      if (quizStateSnap.exists()) {
        const state = quizStateSnap.data();
        const sortedState = {
          ...state,
          questions: sortQuestionOptions(state.questions)
        };
        setSavedQuizState(sortedState);
        setHasResumableQuiz(true);
      } else {
        setHasResumableQuiz(false);
        setSavedQuizState(null);
      }
    } catch (error) {
      console.error('Error loading quiz state: ', error);
    }
  }, [userId]);

  const deleteQuizState = useCallback(async () => {
    if (!userId) return;
    try {
      await deleteDoc(doc(db, 'tcsequizStates', userId));
      setHasResumableQuiz(false);
      setSavedQuizState(null);
    } catch (error) {
      console.error('Error deleting quiz state: ', error);
    }
  }, [userId]);

  useEffect(() => {
    const fetchQuizData = async () => {
      try {
        await loadQuizState();
        if (!hasResumableQuiz) {
          const q = query(collection(db, 'users'), where('quiztype', '==', 'textcompletion'));
          const querySnapshot = await getDocs(q);
          const quizData = querySnapshot.docs.map(doc => doc.data());
          prepareQuizData(quizData);
        }
      } catch (error) {
        console.error('Error fetching quiz data: ', error);
      } finally {
        setLoading(false);
      }
    };
    fetchQuizData();
  }, [loadQuizState, hasResumableQuiz]);

  const prepareQuizData = (quizData) => {
    const singleQuestions = quizData.filter(q => q.type === 'single');
    const seneqQuestions = quizData.filter(q => q.type === 'seneq');
    const doubleQuestions = quizData.filter(q => q.type === 'double');
    const tripleQuestions = quizData.filter(q => q.type === 'triple');

    const selectedSingle = shuffleArray(singleQuestions).slice(0, 6);
    const selectedSeneq = shuffleArray(seneqQuestions).slice(0, 6);
    const selectedDouble = shuffleArray(doubleQuestions).slice(0, 4);
    const selectedTriple = shuffleArray(tripleQuestions).slice(0, 4);

    const randomizedQuestions = shuffleArray([...selectedSingle, ...selectedSeneq, ...selectedDouble, ...selectedTriple])
      .map(question => ({
        ...question,
        options: groupShuffleOptions(question.options)
      }));

    setQuestions(prevState => ({ key: prevState.key + 1, data: randomizedQuestions }));
  };

  const startQuiz = () => {
    setShowWelcome(false);
    setHasResumableQuiz(false);
  };

  const resumeQuiz = useCallback(() => {
    if (savedQuizState) {
      const sortedQuestions = sortQuestionOptions(savedQuizState.questions);
      setQuestions(sortedQuestions);
      setCurrentQuestion(savedQuizState.currentQuestion);
      setSelectedAnswers(savedQuizState.selectedAnswers);
      setShowExplanation(savedQuizState.showExplanation);
      setAnswerStatus(savedQuizState.answerStatus);
      setCorrectAnswers(savedQuizState.correctAnswers);
      setShowScore(savedQuizState.showScore);
      setQuizSummary(savedQuizState.quizSummary);
      setIsAnswerSubmitted(savedQuizState.isAnswerSubmitted);
      setShowWelcome(false);
    }
  }, [savedQuizState]);

  const checkAnswer = (userAnswer, correctAnswer) => {
    if (Array.isArray(correctAnswer)) {
      return userAnswer.length === correctAnswer.length &&
             correctAnswer.every(ans => userAnswer.includes(ans)) &&
             userAnswer.every(ans => correctAnswer.includes(ans));
    }
    return correctAnswer === userAnswer[0];
  };

  const handleSubmit = () => {
    const currentQuiz = questions.data[currentQuestion];
    let userAnswer = [];

    switch (currentQuiz.type) {
      case 'single':
        userAnswer = [selectedAnswers.column1];
        break;
      case 'seneq':
        userAnswer = [selectedAnswers.column1, selectedAnswers.column2].filter(Boolean);
        break;
      default:
        userAnswer = Object.values(selectedAnswers).filter(Boolean);
    }

    const isCorrect = checkAnswer(userAnswer, currentQuiz.correct_answer);

    const newQuizSummary = [
      ...quizSummary,
      {
        question: currentQuiz.question,
        userAnswer: userAnswer,
        correctAnswer: currentQuiz.correct_answer,
        explanation: currentQuiz.explanation,
        isCorrect: isCorrect
      }
    ];

    const newCorrectAnswers = isCorrect ? correctAnswers + 1 : correctAnswers;

    setQuizSummary(newQuizSummary);
    setShowExplanation(true);
    setIsAnswerSubmitted(true);
    setAnswerStatus(isCorrect ? 'correct' : 'incorrect');
    setCorrectAnswers(newCorrectAnswers);

    saveQuizState({
      questions: sortQuestionOptions(questions),
      currentQuestion,
      selectedAnswers,
      showExplanation: true,
      answerStatus: isCorrect ? 'correct' : 'incorrect',
      correctAnswers: newCorrectAnswers,
      showScore,
      quizSummary: newQuizSummary,
      isAnswerSubmitted: true,
    });
  };

  const handleNextQuestion = () => {
    const nextQuestion = currentQuestion + 1;
    if (nextQuestion < questions.data.length) {
      setCurrentQuestion(nextQuestion);
      setSelectedAnswers({
        column1: null,
        column2: null,
        column3: null
      });
      setShowExplanation(false);
      setAnswerStatus(null);
      setIsAnswerSubmitted(false);

      saveQuizState({
        questions: sortQuestionOptions(questions),
        currentQuestion: nextQuestion,
        selectedAnswers: {
          column1: null,
          column2: null,
          column3: null
        },
        showExplanation: false,
        answerStatus: null,
        correctAnswers,
        showScore: false,
        quizSummary,
        isAnswerSubmitted: false,
      });
    } else {
      setShowScore(true);
      saveQuizState({
        questions: sortQuestionOptions(questions),
        currentQuestion: nextQuestion,
        selectedAnswers,
        showExplanation,
        answerStatus,
        correctAnswers,
        showScore: true,
        quizSummary,
        isAnswerSubmitted,
      });
    }
  };

  const handleSingleAnswer = (answer) => {
    return { column1: answer, column2: null, column3: null };
  };

  const handleSeneqAnswer = (prevState, answer) => {
    if (prevState.column1 === answer) {
      return { ...prevState, column1: null };
    } else if (prevState.column2 === answer) {
      return { ...prevState, column2: null };
    } else if (!prevState.column1) {
      return { ...prevState, column1: answer };
    } else if (!prevState.column2) {
      return { ...prevState, column2: answer };
    } else {
      return { ...prevState, column1: answer };
    }
  };

  const handleMultiColumnAnswer = (prevState, answer, columnIndex) => {
    const column = `column${columnIndex + 1}`;
    return {
      ...prevState,
      [column]: prevState[column] === answer ? null : answer
    };
  };

  const handleAnswerClick = (answer, columnIndex) => {
    const currentQuiz = questions.data[currentQuestion];
    setSelectedAnswers(prevState => {
      switch (currentQuiz.type) {
        case 'single':
          return handleSingleAnswer(answer);
        case 'seneq':
          return handleSeneqAnswer(prevState, answer);
        default:
          return handleMultiColumnAnswer(prevState, answer, columnIndex);
      }
    });
  };

  const restartQuiz = async () => {
    setLoading(true);
    try {
      await deleteQuizState();
      setHasResumableQuiz(false);
      setSavedQuizState(null);

      const q = query(collection(db, 'users'), where('quiztype', '==', 'textcompletion'));
      const querySnapshot = await getDocs(q);
      const quizData = querySnapshot.docs.map(doc => doc.data());
      prepareQuizData(quizData);

      setCurrentQuestion(0);
      setCorrectAnswers(0);
      setShowScore(false);
      setSelectedAnswers({ column1: null, column2: null, column3: null });
      setShowExplanation(false);
      setAnswerStatus(null);
      setQuizSummary([]);
      setIsAnswerSubmitted(false);
      setShowWelcome(true);
    } catch (error) {
      console.error('Error restarting quiz: ', error);
    } finally {
      setLoading(false);
    }
  };

  const renderOptions = () => {
    const currentQuiz = questions.data[currentQuestion];
    const options = Object.entries(currentQuiz.options);

    const numColumns = currentQuiz.type === 'single' || currentQuiz.type === 'seneq' ? 1 : 
                       (currentQuiz.type === 'double' ? 2 : 3);
    const optionsPerColumn = Math.ceil(options.length / numColumns);

    return (
      <div className={`tcse-options-grid columns-${numColumns}`}>
        {[...Array(numColumns)].map((_, columnIndex) => (
          <div key={columnIndex} className="tcse-option-column">
            {options.slice(columnIndex * optionsPerColumn, (columnIndex + 1) * optionsPerColumn).map(([key, value]) => (
              <label 
                key={key} 
                className={`
                  tcse-option-label 
                  ${currentQuiz.type === 'seneq' 
                    ? (selectedAnswers.column1 === value || selectedAnswers.column2 === value) ? 'selected' : ''
                    : selectedAnswers[`column${columnIndex + 1}`] === value ? 'selected' : ''}
                  ${showExplanation && currentQuiz.correct_answer.includes(value) ? 'correct' : ''}
                  ${showExplanation && !currentQuiz.correct_answer.includes(value) && 
                    (currentQuiz.type === 'seneq' 
                      ? (selectedAnswers.column1 === value || selectedAnswers.column2 === value)
                      : selectedAnswers[`column${columnIndex + 1}`] === value) 
                    ? 'incorrect' : ''}
                `}
              >
                <input
                  type={currentQuiz.type === 'seneq' ? 'checkbox' : 'radio'}
                  name={`quiz-option-column-${columnIndex}`}
                  value={value}
                  checked={currentQuiz.type === 'seneq' 
                    ? (selectedAnswers.column1 === value || selectedAnswers.column2 === value)
                    : selectedAnswers[`column${columnIndex + 1}`] === value}
                  onChange={() => handleAnswerClick(value, columnIndex)}
                  disabled={showExplanation}
                />
                <span className="tcse-option-text">{key}: {value}</span>
              </label>
            ))}
          </div>
        ))}
      </div>
    );
  };

  const QuizSummary = ({ summary }) => (
    <div className="tcse-quiz-summary">
      <h3>Quiz Summary</h3>
      {summary.map((item, index) => (
        <div key={index} className={`tcse-summary-item ${item.isCorrect ? 'correct' : 'incorrect'}`}>
                    <p><strong>Q{index + 1}:</strong> {item.question}</p>
          <p>Your answer: {Array.isArray(item.userAnswer) ? item.userAnswer.join(', ') : item.userAnswer}</p>
          <p>Correct answer: {Array.isArray(item.correctAnswer) ? item.correctAnswer.join(', ') : item.correctAnswer}</p>
          <p>Explanation: {item.explanation}</p>
        </div>
      ))}
    </div>
  );

  if (loading) {
    return <div className="tcse-quiz-container">Loading...</div>;
  }

  return (
    <div className="tcse-quiz-container">
      {showWelcome ? (
        <div className="tcse-welcome-screen">
          <h2>Welcome to Text Completion and<br/>Sentence Equivalence Quiz</h2>
          <div className="tcse-quiz-instructions">
            <p>
              This quiz is designed to test your vocabulary skills and ability to understand context in complex sentences.
            </p>
            <p>
              You'll be presented with a series of questions designed to assess your language proficiency and analytical skills. These questions will come in four formats:
            </p>
            <ol>
              <li><strong>Single Blank Questions:</strong> Choose the best word to fill in a single blank in a sentence.</li>
              <li><strong>Double Blank Questions:</strong> Select two words that best complete a sentence with two blanks.</li>
              <li><strong>Triple Blank Questions:</strong> Choose three words to fill in three blanks in a more complex sentence.</li>
              <li><strong>Sentence Equivalence Questions:</strong> Select two words that, when used to complete the sentence, result in two sentences with the same meaning.</li>
            </ol>
            <p>
              Remember to read each sentence carefully and consider the overall context when selecting your answers. Pay attention to the tone, style, and intended meaning of the sentence.
            </p>
            <p>
              This quiz is designed to challenge your vocabulary and contextual understanding. Take your time to consider each option carefully, and don't hesitate to use your knowledge of word roots, prefixes, and suffixes to help you make informed choices. Good luck!
            </p>
          </div>
          {hasResumableQuiz && savedQuizState && (
            <div className="tcse-saved-quiz-info">
              <p>You have a saved quiz. Would you like to resume?</p>
              <p>Progress: {savedQuizState.currentQuestion + 1} / {savedQuizState.questions.data.length} questions</p>
              <p>Current Score: {savedQuizState.correctAnswers}</p>
              <button onClick={resumeQuiz} className="quiz-button">Resume Quiz</button>
            </div>
          )}
          <button onClick={startQuiz}>Start A New Quiz</button>
        </div>
      ) : showScore ? (
        <>
          <h2>Quiz Completed!</h2>
          <h3>You scored {correctAnswers} out of {questions.data.length}</h3>
          <QuizSummary summary={quizSummary} />
          <button onClick={restartQuiz} className="tcse-restart-button">Restart Quiz</button>
        </>
      ) : (
        <>
          <h2>Question {currentQuestion + 1} of {questions.data.length}</h2>
          <p className="tcse-question-text">{questions.data[currentQuestion].question}</p>
          <p className="tcse-question-type">
            {questions.data[currentQuestion].type === 'single' ? 'Select one answer, this is a single blank text completion question' : 
             questions.data[currentQuestion].type === 'seneq' ? 'Select two answers, this is a sentence equivalence question' :
             questions.data[currentQuestion].type === 'double' ? 'Select one answer from each of the two columns, this is a double blank text completion question' :
             questions.data[currentQuestion].type === 'triple' ? 'Select one answer from each of the three columns, this is a triple blank text completion question' :
             'Select one answer from each of the three columns'}
          </p>
          {renderOptions()}
          {!isAnswerSubmitted && (
            <button 
              onClick={handleSubmit} 
              disabled={
                (questions.data[currentQuestion].type === 'single' && !selectedAnswers.column1) ||
                (questions.data[currentQuestion].type === 'seneq' && (!selectedAnswers.column1 || !selectedAnswers.column2)) ||
                (questions.data[currentQuestion].type === 'double' && (!selectedAnswers.column1 || !selectedAnswers.column2)) ||
                (questions.data[currentQuestion].type === 'triple' && (!selectedAnswers.column1 || !selectedAnswers.column2 || !selectedAnswers.column3))
              }
            >
              Submit
            </button>
          )}
          {showExplanation && (
            <div className="tcse-explanation">
              <p className={`tcse-answer-status ${answerStatus}`}>
                {answerStatus === 'correct' ? 'Correct!' : 'Incorrect!'}
              </p>
              <p>{questions.data[currentQuestion].explanation}</p>
            </div>
          )}
          {isAnswerSubmitted && (
            <div className="tcse-button-container">
              <button onClick={handleNextQuestion}>
                {currentQuestion < questions.data.length - 1 ? 'Next Question' : 'Finish Quiz'}
              </button>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default TcseQuiz;