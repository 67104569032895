// Login.js
import React from "react";
import { auth, provider, signInWithPopup } from "./firebase";
import { useNavigate, useLocation } from "react-router-dom";
import "./Login.css";

const Login = () => {
  const navigate = useNavigate();
  const location = useLocation();

  // Get the 'from' location state or default to '/'
  const from = location.state?.from?.pathname || "/";

  const handleLogin = async () => {
    try {
      const result = await signInWithPopup(auth, provider);
      const user = result.user;
      console.log("User signed in: ", user);
      navigate(from, { replace: true });
    } catch (error) {
      if (error.code === 'auth/popup-blocked') {
        console.error("Popup was blocked by the browser");
        // Consider showing a user-friendly message
      } else if (error.code === 'auth/popup-closed-by-user') {
        console.error("Popup was closed by the user");
        // Consider showing a user-friendly message
      } else {
        console.error("Error during sign-in: ", error);
      }
    }
  };

  return (
    <div className="login-container">
      <div className="login-box">
        <h2 className="login-title">Sign in to access:</h2>
        <ul className="login-benefits">
          <li>Sentence Completion Modules</li>
          <li>Vocabulary Quizzes</li>
          <li>Math Problem Sets</li>
          <li>Reading Comprehension Exercises</li>
          <li>Personalized Study Plans</li>
        </ul>
        <button onClick={handleLogin} className="login-button">
          <img src="/google-icon.svg" alt="Google" />
          <span>Login with Google</span>
        </button>
      </div>
    </div>
  );
};

export default Login;