// src/components/blog/Post.js
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import styles from './Post.module.css';

function Post() {
  const { slug } = useParams();
  const [content, setContent] = useState('');
  const [meta, setMeta] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const parseMarkdown = (text) => {
    // Regular expression to capture front matter and content
    const frontMatterRegex = /^---\s*\n([\s\S]*?)\n---\s*\n([\s\S]*)$/;
    const matches = frontMatterRegex.exec(text);

    if (matches) {
      // Extract front matter and content
      const metaString = matches[1];
      const content = matches[2];

      // Parse front matter lines into an object
      const metaLines = metaString.split('\n');
      const meta = {};
      metaLines.forEach((line) => {
        const [key, ...rest] = line.split(':');
        if (key && rest.length > 0) {
          meta[key.trim()] = rest.join(':').trim().replace(/(^"|"$)/g, '');
        }
      });

      return { meta, content };
    } else {
      // No front matter found
      return { meta: {}, content: text };
    }
  };

  useEffect(() => {
    const fetchPost = async () => {
      try {
        const response = await fetch(`/posts/${slug}.md`);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const text = await response.text();
        const { meta, content } = parseMarkdown(text);
        setMeta(meta);
        setContent(content);
      } catch (err) {
        console.error('Error fetching post:', err);
        setError(true);
      } finally {
        setLoading(false);
      }
    };
    fetchPost();
  }, [slug]);

  if (loading) {
    return <div className={styles.loading}>Loading post...</div>;
  }

  if (error) {
    return (
      <div className={styles.error}>
        Sorry, the post you're looking for does not exist.
      </div>
    );
  }

  return (
    <div className={styles['post-wrapper']}>
      <div className={styles['post-main']}>
        <h1 className={styles['post-title']}>{meta.title}</h1>
        <p className={styles['post-date']}>{meta.date}</p>
        <div className={styles['post-content']}>
          <ReactMarkdown remarkPlugins={[remarkGfm]}>{content}</ReactMarkdown>
        </div>
      </div>
    </div>
  );
}

export default Post;