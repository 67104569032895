// App.js
import React, { useEffect, useState } from 'react';
import NotFound from './NotFound';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { auth } from './firebase';
import { onAuthStateChanged } from 'firebase/auth';
import AboutUs from './AboutUs';
import Disclaimer from './disclaimer';
import Navbar from './Navbar';
import ContentWrapper from './ContentWrapper';
import Quiz from './Quiz';
import TcseQuiz from './tcseQuiz';
import RcQuiz from './rcQuiz';
import SatMathQuiz from './satmathQuiz';
import SynonymsQuiz from './synonymsQuiz';
import VocabularyBuilder from './vocabularyBuilder';
import MathQuizData from './mathQuiz';
import SatrwQuiz from './satrwQuiz';
import ContactForm from './ContactForm';
import Login from './Login';
import BlogList from './components/blog/BlogList';
import Post from './components/blog/Post';
import ProfilePage from './ProfilePage';
import Home from './Home';
import 'bootstrap/dist/css/bootstrap-grid.min.css';
import 'bootstrap/dist/css/bootstrap-utilities.min.css';
import PrivateRoute from './PrivateRoute'; // Import the PrivateRoute component

function App() {
  const [user, setUser] = useState(null); // State to hold the user object
  const [loading, setLoading] = useState(true); // State to handle loading state

  useEffect(() => {
    // Set up an observer on the Auth object to get user state changes
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUser(user); // User is signed in
      } else {
        setUser(null); // No user is signed in
      }
      setLoading(false); // Set loading to false once auth state is determined
    });

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, []);

  // Show a loading spinner or placeholder while the auth state is being determined
  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <Router>
      <div className="App">
        <Navbar user={user} /> {/* Pass the user state to Navbar */}
        <ContentWrapper>
          <main>
            <Routes>
              <Route path="/" element={<Home />} />

              {/* Protected Routes */}
              <Route
                path="/ContactForm"
                element={
                  <PrivateRoute user={user}>
                    <ContactForm />
                  </PrivateRoute>
                }
              />
              <Route
                path="/quiz"
                element={
                  <PrivateRoute user={user}>
                    <Quiz />
                  </PrivateRoute>
                }
              />
              <Route
                path="/tcseQuiz"
                element={
                  <PrivateRoute user={user}>
                    <TcseQuiz />
                  </PrivateRoute>
                }
              />
              <Route
                path="/rcQuiz"
                element={
                  <PrivateRoute user={user}>
                    <RcQuiz />
                  </PrivateRoute>
                }
              />
              <Route
                path="/synonymsQuiz"
                element={
                  <PrivateRoute user={user}>
                    <SynonymsQuiz />
                  </PrivateRoute>
                }
              />
              <Route
                path="/vocabularyBuilder"
                element={
                  <PrivateRoute user={user}>
                    <VocabularyBuilder />
                  </PrivateRoute>
                }
              />
              <Route
                path="/mathQuiz"
                element={
                  <PrivateRoute user={user}>
                    <MathQuizData />
                  </PrivateRoute>
                }
              />
              <Route
                path="/satrwQuiz"
                element={
                  <PrivateRoute user={user}>
                    <SatrwQuiz />
                  </PrivateRoute>
                }
              />
              <Route
                path="/satmathQuiz"
                element={
                  <PrivateRoute user={user}>
                    <SatMathQuiz />
                  </PrivateRoute>
                }
              />
              <Route
                path="/ProfilePage"
                element={
                  <PrivateRoute user={user}>
                    <ProfilePage />
                  </PrivateRoute>
                }
              />

              {/* Public Routes */}
              <Route path="/AboutUs" element={<AboutUs />} />
              <Route path="/disclaimer" element={<Disclaimer />} />
              <Route path="/blog" element={<BlogList />} />
              <Route path="/blog/:slug" element={<Post />} />

              {/* Login Route */}
              <Route
                path="/login"
                element={user ? <Navigate to="/quiz" replace /> : <Login />}
              />

              {/* Catch-all Route */}
              <Route path="*" element={<NotFound />} />
            </Routes>
          </main>
        </ContentWrapper>
      </div>
    </Router>
  );
}

export default App;