// src/posts/postsMetadata.js
const posts = [
  {
    slug: 'essential-tips-for-success-on-the-new-digital-sat-reading-and-writing-section',
    title: 'Essential Tips for Success on the New Digital SAT Reading and Writing Section',
    date: '2024-11-12',
  },
  // Add more posts here
];

export default posts;