// PrivateRoute.js
import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';

const PrivateRoute = ({ user, children }) => {
  const location = useLocation();

  if (user) {
    return children;
  } else {
    return <Navigate to="/login" replace state={{ from: location }} />;
  }
};

export default PrivateRoute;